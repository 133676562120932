import React, { useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/ja";
import SEO from "../components/seo";
import "../style/member-benefit.css";

import $ from "jquery";

const MemberBenefitPage = (props) => {
  useEffect(() => {
    expandData();
  }, []);

  const expandData = () => {
    $(".benefit__mobile label").on("click", function () {
      $(this).toggleClass("benefit__tab--active");
    });
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
          margin: 0px auto
        }
        .benefit__special .table--benefit {
          display: inline-table
        }
      `,
        }}></style>
      <Layout location={props.location}>
        <SEO
          title="More benefits as you grow your membership status "
          keyword="ayana member benefits, benefits for member, ayana rewards"
          decription="Become an AYANA Rewards member and you will receive more benefits as you stay. As you grow your membership level, you will receive more as well. Become a member now and start to earn points!"
        />

        <>
          <div id="contentWrap">
            <div id="index_content" className="admin-setting">
              <div className="benefit__banner">&nbsp;</div>
              <div className="benefit">
                <div className="benefit__banner__mobile mobile--only">
                  <img
                    className="full-width"
                    src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                  />
                </div>
                <div className="benefit__content">
                  <div className="container container--600">
                    <h1 className="text--center">メンバー特典</h1>
                    <p className="text--center">
                      アヤナリワード会員になることで、私たちの魅力的な目的地での特別な体験やラグジュアリーをお楽しみいただけます。3段階のメンバーシッププログラムでは、支出や訪問に応じて増える特典や特権に加え、エリートステータスへのレベルアップをスピーディに実現します。宿泊や訪問を通じて、あなたにふさわしい豪華な報酬を体験してください。
                    </p>
                  </div>
                  <div className="container container--1000">
                    <table className="table table--benefit table--benefit--main">
                      <thead>
                        <tr>
                          <th valign="middle">プログラムの概要</th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              シルバー{" "}
                            </span>
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              ゴールド{" "}
                            </span>
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              プラチナ{" "}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            会員レベルをアップグレードするために必要な最低ポイント/宿泊数{" "}
                          </td>
                          <td>-</td>
                          <td>20泊または8,000ポイント</td>
                          <td>50泊または20,000ポイント</td>
                        </tr>
                        <tr>
                          <td>
                            会員レベルを維持するために必要な最低ポイント/宿泊数{" "}
                          </td>
                          <td>-</td>
                          <td>20泊または8,000ポイント</td>
                          <td>50泊または20,000ポイント</td>
                        </tr>
                        <tr>
                          <td>ポイント失効期間 </td>
                          <td colspan="3">最後の資格活動から2年間 </td>
                        </tr>
                        <tr>
                          <td>レベルの有効期間または保持期間 </td>
                          <td colspan="3">
                            最後のアップグレード/ダウングレード/保持に続く翌カレンダー年の12月31日まで有効{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>アクティブステータス </td>
                          <td colspan="3">
                            {" "}
                            会員は、最後の記録された活動日から24か月以内に、ウェブサイトのログイン活動、資格のある宿泊、資格のある支出、またはアヤナリワードポイントの引き換えを行う必要があります。{" "}
                          </td>
                        </tr>
                        <tr className="table--benefit--subheader">
                          <td colspan="4">ポイント獲得特典</td>
                        </tr>
                        <tr>
                          <td>IDR10,000利用毎の獲得ポイント数</td>
                          <td>1 ポイント</td>
                          <td>1 ポイント</td>
                          <td>1 ポイント</td>
                        </tr>
                        <tr>
                          <td>
                            IDR15,000利用毎の獲得ポイント数（デロニックスのみ）
                          </td>
                          <td>1 ポイント</td>
                          <td>1 ポイント</td>
                          <td>1 ポイント</td>
                        </tr>
                        <tr>
                          <td>対象となる宿泊でポイントを獲得</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>飲食のご利用でポイントを獲得</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>スパトリートメントのご利用でポイントを獲得</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr className="table--benefit--subheader">
                          <td colspan="4">会員特典</td>
                        </tr>
                        <tr>
                          <td>会員専用ルーム割引** </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            予約した同じ部屋カテゴリでの無料ワンランク部屋アップグレード*{" "}
                          </td>
                          <td>&nbsp;</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>対象支出に対するボーナスポイント </td>
                          <td>&nbsp;</td>
                          <td>25%</td>
                          <td>50%</td>
                        </tr>
                        <tr>
                          <td>アーリーチェックイン* </td>
                          <td>&nbsp;</td>
                          <td>11:00</td>
                          <td>11:00</td>
                        </tr>
                        <tr>
                          <td>レイトチェックアウト* </td>
                          <td>&nbsp;</td>
                          <td>16:00</td>
                          <td>18:00</td>
                        </tr>
                        <tr>
                          <td>無料1回朝食 </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>2名</td>
                        </tr>
                        <tr>
                          <td>ウェルカムギフト** </td>
                          <td>&nbsp;</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>誕生日祝いの無料アメニティ** </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>ウェルネスプログラム特典** </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">参加ブランドでの会員専用特典*** </td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            カワノグループレストランでの会員専用割引（全国対応）{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>10%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            劉麗宮シーフードレストラン（ジャカルタ）での会員専用割引{" "}
                          </td>
                          <td>15%</td>
                          <td>15%</td>
                          <td>15%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            アヤナバリのレストランでの会員専用割引{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            デロニックスホテルカラワンのマイアミレストランでの会員専用割引{" "}
                          </td>
                          <td>10%</td>
                          <td>15%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            リバーサイドゴルフクラブでの会員専用グリーンフィー割引{" "}
                          </td>
                          <td>15%</td>
                          <td>25%</td>
                          <td>40%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            アヤナミッドプラザジャカルタのアヤナスパでの会員専用割引{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            アヤナ コモド ワエチチュ
                            ビーチのアヤナスパでの会員専用割引{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            アヤナスパ - アヤナバリでの会員専用特典{" "}
                          </td>
                          <td>追加15分のマッサージ</td>
                          <td>追加15分のマッサージ</td>
                          <td>追加30分のマッサージ</td>
                        </tr>
                      </tbody>
                    </table>
                    <small className="mart--lg" style={{ display: "block" }}>
                      * メンバーの到着時の空き状況に応じて
                      <br />
                      ** 施設によって異なる
                      <br />
                      ***
                      <Link className="link" to="/ja/terms-and-conditions-8">
                        <span> 利用規約が適用されます </span>
                      </Link>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </>
  );
};

export default MemberBenefitPage;
